export let appConfig = {

    
// PARAMETRES DE L'APPLICATION
appName:"Katana",
appURL:"https://www.katana-crm.com",
appLogoUrl:"https://cdn.infographart.com/logos/KATANA/K/K-tana.svg",

appTransitionOpen:"https://hostcrush.cloud/echange/VueDesignProps/Transitions/Rounded-1-Close.png",
appTransitionOpenLazy:"https://hostcrush.cloud/echange/VueDesignProps/Transitions/Rounded-1-Close_Lazy.png",
appTransitionClose:"https://hostcrush.cloud/echange/VueDesignProps/Transitions/Rounded_1-Close.png",
appTransitionCloseLazy:"https://hostcrush.cloud/echange/VueDesignProps/Transitions/Rounded_1-Lazy-Close.png",

// SOCIALS
appSocialFacebook:"",
appSocialYoutube:"",

appSocials:[{
	displayName: 'Facebook',
	link: 'https://www.facebook.com/',
	icon: 'mdi-facebook',
},{
	displayName: 'Youtube',
	link: 'https://www.youtube.com/',
	icon: 'mdi-youtube',
},
],

//CONTACT

appContactInFooter:1, // Ajouter le(s) adresse(s) dans le footer ? (bool)

appContactName1:"InfographArt",
appContactNumber1:"06 36 96 21 66",
appContactAdress1:"22 Rond Point des Arênes - 13200 - Arles",
appContactAdressMap1:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.576393498491!2d4.627804615106864!3d43.67777907912044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b6766caef2fd77%3A0x286d4dcf96f654ca!2s22%20Rond-Point%20des%20Ar%C3%A8nes%2C%2013200%20Arles!5e0!3m2!1sfr!2sfr!4v1621628976176!5m2!1sfr!2sfr",


appContact2:0, // Utiliser seconde adresse ? (bool)

appContactName2:"Renault Pro+",
appContactNumber2:"04 77 44 86 33",
appContactAdress2:"35 Boulevard Charles de Gaulle 42120 Le Coteau",
appContactAdressMap2:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2770.814476394645!2d4.094369315575984!3d46.014895179111356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f40facf000b11d%3A0xe26965be0b4df908!2s31%20Boulevard%20Charles%20de%20Gaulle%2C%2042120%20Le%20Coteau!5e0!3m2!1sfr!2sfr!4v1614697962700!5m2!1sfr!2sfr",


//HORAIRES

appContactHours:[{
	day: 'Lundi',
	ma: '08:00 - 12:00',
	am: '14:00 - 17:00',
},{
	day: 'Mardi',
	ma: '08:00 - 12:00',
	am: '14:00 - 17:00',
},{
	day: 'Mercredi',
	ma: '08:00 - 12:00',
	am: '14:00 - 17:00',
},{
	day: 'Jeudi',
	ma: '08:00 - 12:00',
	am: '14:00 - 17:00',
},{
	day: 'Vendredi',
	ma: '08:00 - 12:00',
	am: '14:00 - 17:00',
},
],

performance:false,

// MAINTENANCE - COMMENTER LE ROUTER AUTO VERS LA PAGE MAINTENANCE
maintenanceMode:false,
};