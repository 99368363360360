import Vue from "vue";
import VueRouter from "vue-router";

import Vuex from "vuex";
import store from "@/store/index.js";

import * as firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter);
Vue.use(Vuex);

const routes = [
	{
		path: "/access",
		name: "Access",
		component: () => import('@/views/Access.vue')

	},
	{
		path: "/",
		name: "dashboard",
		component: () => import('@/views/Dashboard.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		}
	},
	{
		path: "/profile",
		name: "Profile",
		component: () => import('@/views/Profile'),
		meta: {
			requiresAuth: true,
			safePlace: true
		}
	},
	{
		path: "/my-company",
		name: "Mon entreprise",
		component: () => import('@/views/MyCompany.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		}
	},

	// MODULES
	{
		path: "/projects",
		name: "Projects",
		component: () => import('@/views/Projects'),
		meta: {
			requiresAuth: true,
			module: "Dk1wdkEvdgyIPmJAg1uv",
			safePlace: true
		}

	},
	{
		path: "/team",
		name: "Team",
		component: () => import('@/views/Teams.vue'),
		meta: {
			requiresAuth: true,
			module: "GPvYlONpjCDqzW6sckkw",
			safePlace: true
		}
	},
	{
		path: "/customers",
		name: "Customers",
		component: () => import('@/views/Customers.vue'),
		meta: {
			requiresAuth: true,
			modules: "8JJgD8UzYi1c6H3tukKF",
			safePlace: true
		}
	},
	{
		path: "/companies",
		name: "Companies",
		component: () => import('@/views/Companies.vue'),
		meta: {
			requiresAuth: true,
			module: "MuIS4a0uWMkvYxL2o9v1",
			safePlace: true
		}
	},
	{
		path: "/quotes",
		name: "Quotes",
		component: () => import('@/views/Quotes.vue'),
		meta: {
			requiresAuth: true,
			module: "YzeCWO5vQIgYKrdZCuWz",
			safePlace: true
		}
	},
	{
		path: "/products",
		name: "Products",
		component: () => import('@/views/Products.vue'),
		meta: {
			requiresAuth: true,
			module: "dBNa5WjEnL1tjUJBDVHd",
			safePlace: true
		}
	},
	{
		path: "/tools",
		name: "Tools",
		component: () => import('@/views/Tools.vue'),
		meta: {
			requiresAuth: true,
			module: "iqgmYXQqUR2Gxk8DfsqD",
			safePlace: true
		}
	},
	{
		path: "/tools/qrcode",
		name: "QR_Code",
		component: () => import('@/views/tools/Gen_qr.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		}
	},
	{
		path: "/tools/import",
		name: "Import",
		component: () => import('@/views/tools/Import.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		}
	},
	{
		path: "/blogs",
		name: "Blogs",
		component: () => import(`@/views/Blogs.vue`),
		meta: {
			requiresAuth: true,
			module: "zS95PXCJ8QRCXsNgRMSX",
			safePlace: true
		}
	},
	{
		path: "/tasks",
		name: "Tasks",
		component: () => import(`@/views/Tasks.vue`),
		meta: {
			requiresAuth: true,
			safePlace: true
		}
	},
	{
		path: "/messaging",
		name: "Messaging",
		component: () => import(`@/views/Messaging.vue`),
		meta: {
			requiresAuth: true,
			safePlace: true,
			module: "GkQYUDfVSOv91r2CS9KN",
		}
	},

	// ADMINISTRATION
	{
		path: "/administration",
		name: "Administration",
		component: () => import('@/views/administration/Dashboard.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		}
	},
	{
		path: "/administration/creation",
		name: "Creation",
		component: () => import('@/views/administration/Creation.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		}
	},
	{
		path: "/administration/company/:id",
		name: "AdminCompany",
		component: () => import('@/views/administration/manage/Company.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		},
		props: true
	},
	{
		path: "/administration/customer/:id",
		name: "AdminCustomer",
		component: () => import('@/views/administration/manage/Customer.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		},
		props: true
	},
	{
		path: "/administration/sandbox",
		name: "Sandbox",
		component: () => import('@/views/administration/Sandbox.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		},
		props: true
	},
	{
		path: "/administration/dbcrawler",
		name: "DB_Crawler",
		component: () => import('@/views/administration/DBCrawler.vue'),
		meta: {
			requiresAuth: true,
			safePlace: true
		},
		props: true
	},
	// FICHES INDIVIDUELLES
	{
		path: "/record/company/:id",
		name: "Company",
		component: () => import('@/views/record/Company.vue'),
		meta: {
			requiresAuth: true,
			module: "MuIS4a0uWMkvYxL2o9v1"
		},
		props: true
	},
	{
		path: "/record/product/:id",
		name: "Product",
		component: () => import('@/views/record/Product.vue'),
		meta: {
			requiresAuth: true,
			module: "dBNa5WjEnL1tjUJBDVHd"
		},
		props: true
	},
	{
		path: "/record/customer/:id",
		name: "Customer",
		component: () => import('@/views/record/Customer.vue'),
		meta: {
			requiresAuth: true,
			module: "8JJgD8UzYi1c6H3tukKF"
		},
		props: true
	},
	{
		path: "/record/quote/:id",
		name: "Quote",
		component: () => import('@/views/record/Quote.vue'),
		props: true
	},
	{
		path: "/record/project/:id",
		name: "Project",
		component: () => import(`@/views/record/Project.vue`),
		meta: {
			requiresAuth: true,
			module: "Dk1wdkEvdgyIPmJAg1uv"
		},
		props: true
	},

	{
		path: "/blog/:id",
		name: "Blog",
		component: () => import(`@/views/record/Blog.vue`),
		meta: {
			requiresAuth: true,
			module: "zS95PXCJ8QRCXsNgRMSX"
		},
		props: true
	},
	{
		path: "/blog/:id/add",
		name: "AddBlog",
		component: () => import(`@/views/blog/Add.vue`),
		meta: {
			requiresAuth: true,
			module: "zS95PXCJ8QRCXsNgRMSX"
		},
		props: true,
		children: [{
			path: ':modArticle'
		}]
	},
	{
		path: "/blog/:id/articles",
		name: "Articles",
		component: () => import(`@/views/blog/Articles.vue`),
		meta: {
			requiresAuth: true,
			module: "zS95PXCJ8QRCXsNgRMSX"
		},
		props: true
	},
	{
		path: "/blog/:id/article/:idArticle",
		name: "Article",
		component: () => import(`@/views/record/Article.vue`),
		meta: {
			requiresAuth: true,
			module: "zS95PXCJ8QRCXsNgRMSX"
		},
		props: true
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	const isAuthenticated = firebase.auth().currentUser;

	if (to.meta.requiresAuth && !isAuthenticated) {
		next("/access");
	} else {
		next();
	}

	if (to.meta.module) {
		var accessPromise = store.dispatch("hasAccess", {
			module: to.meta.module
		}).then((result) => {
			return result;
		});

		const checkAccess = async () => {
			const hasAccess = await accessPromise;
			
			if (!hasAccess) {
				next("/");
				store.dispatch('setSnackbar', {
					text: `Vous n'avez pas accès à ce contenu !`,
					color: 'error'
				});
			}
		};

		checkAccess();
	}
})

export default router;
