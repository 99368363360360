<template>
	<div>
		<v-dialog :value="value" transition="dialog-top-transition" max-width="1280" @input="$emit('input')">
			<v-form ref="form">
				<v-row class="maxWidth mx-auto pa-0 ma-0">
					<v-col cols="12">
						<v-card class="pa-4 ma-0 glassmorphism mx-auto" elevation="0" :color="$vuetify.theme.dark ? 'transparent' : 'white'">
							<v-row>
								<v-col cols="12">
									<Title weight="regular" titleSize="h2">Ajouter une Tâche</Title>
								</v-col>
								<v-col cols="12" md="2" sm="4" xs="12">
									<v-select v-model="taskEntry.status" :items="this.$listUtils.elementStatus" label="Statut" :rules="this.$fieldsRules.required('statut')"></v-select>
								</v-col>
								<v-col cols="12" md="8" sm="12" xs="12">
									<v-text-field label="Tâche" hint="Nom de la tâche • ex: Appeler M. Dupont" v-model="taskEntry.task" :rules="$fieldsRules.required('tâche', true)">
									</v-text-field>
								</v-col>
								<v-col cols="12" md="2" sm="12" xs="12">
									<v-select v-model="taskEntry.category" :items="this.$listUtils.itemCategory" item-text="label" label="Catégorie" :rules="this.$fieldsRules.required('catégorie', true)"></v-select>
								</v-col>
								<v-col cols="12">
									<v-row>
										<v-col cols="12" sm="5">
											<v-autocomplete clearable multiple v-model="taskEntry.participants" :items="people" chips deletable-chips color="blue-grey lighten-2" label="Participants" item-text="[name, id]" item-value="id">
												<template v-slot:selection="data">
													<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
														<v-avatar left>
															<span v-if="!data.item.avatar && data.item.firstName" class="font-weight-regular">{{ data.item.firstName[0] }}{{ data.item.lastName[0] }}</span>
															<span v-else-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>

															<v-img v-else :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
														</v-avatar>
														{{ data.item.name }}
													</v-chip>
												</template>
												<template v-slot:item="data">
													<template v-if="data.header">
														<v-list-item-content v-text="data.item"></v-list-item-content>
													</template>
													<template v-else>
														<v-list-item-avatar>
															<span v-if="!data.item.avatar && data.item.firstName" class="font-weight-regular">{{ data.item.firstName[0] }}{{ data.item.lastName[0] }}</span>
															<span v-else-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>
															<v-img v-else contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
														</v-list-item-avatar>
														<v-list-item-content>
															<v-list-item-title>{{ data.item.name }}</v-list-item-title>
															<v-list-item-subtitle v-if="data.item.denom != 'utilisateur'" v-html="'Numéro ' + data.item.denom + ' : ' + data.item.content">
															</v-list-item-subtitle>
														</v-list-item-content>
													</template>
												</template>
											</v-autocomplete>
										</v-col>
										<v-col cols="12" sm="4">
											<v-autocomplete clearable v-model="taskEntry.project" :items="projects" chips color="blue-grey lighten-2" label="Projet" item-text="[name, id]" item-value="id">
												<template v-slot:selection="data">
													<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
														<v-avatar left>
															<span v-if="!data.item.avatar && data.item.firstName" class="font-weight-regular">{{ data.item.firstName[0] }}{{ data.item.lastName[0] }}</span>
															<span v-else-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>
															<v-img v-else :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
														</v-avatar>
														{{ data.item.name }}
													</v-chip>
												</template>
												<template v-slot:item="data">
													<template v-if="data.header">
														<v-list-item-content v-text="data.item"></v-list-item-content>
													</template>
													<template v-else>
														<v-list-item-avatar>
															<span v-if="!data.item.avatar && data.item.firstName" class="font-weight-regular">{{ data.item.firstName[0] }}{{ data.item.lastName[0] }}</span>
															<span v-else-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>

															<v-img v-else contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
														</v-list-item-avatar>
														<v-list-item-content>
															<v-list-item-title>{{ data.item.name }}</v-list-item-title>
														</v-list-item-content>
													</template>
												</template>
											</v-autocomplete>
										</v-col>
										<v-col cols="12" sm="3">
											<v-autocomplete v-model="company" :items="companies" chips color="blue-grey lighten-2" label="Destination de la tâche" hint="Entreprise proriétaire de la tâche" item-text="[name, id]" item-value="id">
												<template v-slot:selection="data">
													<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
														<v-avatar left>
															<span v-if="!data.item.avatar && data.item.firstName" class="font-weight-regular">{{ data.item.firstName[0] }}{{ data.item.lastName[0] }}</span>
															<span v-else-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>

															<v-img v-else :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
														</v-avatar>
														{{ data.item.name }}
													</v-chip>
												</template>
												<template v-slot:item="data">
													<v-list-item-avatar>
														<span v-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>
														<v-img v-else contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title>{{ data.item.name }}</v-list-item-title>
													</v-list-item-content>
												</template>
											</v-autocomplete>
										</v-col>
									</v-row>
								</v-col>

								<v-col cols="12" sm="12" xs="12">
									<Title weight="regular" titleSize="h2">Details</Title>

									<v-card outlined>
										<vue-editor id="vueEditor" v-model="taskEntry.content" />
									</v-card>
								</v-col>

								<v-col cols="12" md="5" sm="12" xs="12">
									<v-select v-model="taskEntry.priority" :items="this.$listUtils.priority" label="Priorité" :rules="this.$fieldsRules.required('priorité', true)">
									</v-select>
								</v-col>

								<v-col cols="12" md="5" sm="12" xs="12">
									<v-row>
										<v-col cols="12" sm="6">
											<v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="taskEntry.dueDate" transition="scale-transition" offset-y min-width="auto">
												<template v-slot:activator="{ on, attrs }">
													<v-text-field v-model="formatDate" label="Date limite" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
													</v-text-field>
												</template>
												<v-date-picker first-day-of-week="1" v-model="taskEntry.dueDate" scrollable @change="$refs.menu.save(taskEntry.dueDate)"></v-date-picker>
											</v-menu>
										</v-col>
										<v-col cols="12" sm="6" v-if="taskEntry.dueDate">
											<v-menu ref="menu_1" v-model="menu1_1" :close-on-content-click="false" :nudge-right="40" :return-value.sync="time" transition="scale-transition" max-width="290" offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field v-model="time" label="Heure limite" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
												</template>
												<v-time-picker v-if="menu1_1" v-model="time" @click:minute="$refs.menu_1.save(time)" format="24hr"></v-time-picker>
											</v-menu>
										</v-col>
									</v-row>
								</v-col>

								<v-col cols="12" md="2" sm="4" xs="12">
									<v-btn class="float-right neon" block outlined color="success" @click="submitTask()">Ajouter</v-btn>
								</v-col>

							</v-row>
						</v-card>
					</v-col>
				</v-row>

			</v-form>
		</v-dialog>
	</div>
</template>


<script>
	import { VueEditor } from "vue2-editor";

	export default {
		props: {
			'userInfo': {
				type: Object
			},
			'value': {
				type: Boolean
			},
			'refreshTasks': {
				type: Boolean,
				default: false
			}
		},
		components: {
			VueEditor
		},
		data() {
			return {
				menu: false,

				time: "23:59",
				menu1_1: false,

				taskEntry: {
					...this.$models.task,
					priority: this.$listUtils.priority[2],
					status: this.$listUtils.elementStatus[1]
				},

				people: [],
				users: [],
				projects: [],

				company: '',
				companies: []
			}
		},
		computed: {
			formatDate() {
				return this.taskEntry.dueDate ? this.$moment(this.taskEntry.dueDate).format('DD/MM/YYYY') : ''
			},
			dateTime() {
				return new Date(`${this.taskEntry.dueDate} ${this.time}`);
			}
		},
		methods: {
			submitTask() {
				if (!this.$refs.form.validate()) return;

				const task = {
					...this.taskEntry,
					'status': this.$listUtils.elementStatus.indexOf(this.taskEntry.status),
					'category': this.taskEntry.category,
					'priority': this.$listUtils.priority.indexOf(this.taskEntry.priority),

					'creationDate': new Date(),
					'lastUpdate': new Date(),

					'author': this.userInfo.id
				}

				if (!task.participants || task.participants.length == 0) delete task.participants;
				if (!task.project) delete task.project;

				if (this.taskEntry.dueDate) task.dueDate = new Date(this.dateTime);

				this.$db.collection("companies").doc(this.company).collection("tasks").add(task).then(() => {
					this.$refs.form.resetValidation();

					this.$store.dispatch('setSnackbar', {
						text: `La tâche ${task.task} a été ajouté !`,
					});

					this.$emit('input', false);
					if(this.refreshTasks) this.$emit('init');

					this.taskEntry = {
						priority: this.$listUtils.priority[2],
						status: this.$listUtils.elementStatus[1]
					};
				});
			},
			init() {
				this.people = [];
				this.users = [];
				this.projects = [];

				this.$functions.queryDatabase(`companies/${this.company}/users`).then((res) => {
					this.users.push({
						header: 'Utilisateurs'
					});

					res.forEach((user) => {
						this.$functions.queryDatabase(`users/${user.id}`).then((res2) => {

							res2.forEach((userData) => {
								this.users.push({
									'name': userData.data().lastName.toUpperCase() + " " + userData.data().firstName,
									'id': user.id,
									'avatar': userData.data().avatar,
									'denom': "utilisateur"
								});
							});

						});
					});
				});

				this.$functions.queryDatabase(`companies/${this.company}/customers`).then((res) => {
					this.people.push({
						header: 'Clients'
					});

					res.forEach((document) => {
						this.people.push({
							...document.data(),
							'name': document.data().lastName.toUpperCase() + " " + document.data().firstName,
							'content': document.data().nClient,
							'id': document.id,
							'avatar': document.data().avatar,
							'denom': "client"
						});
					})
				});

				this.$functions.queryDatabase(`companies/${this.company}/companies`).then((res) => {
					this.people.push({
						header: 'Entreprises'
					});

					res.forEach((document) => {
						this.people.push({
							...document.data(),
							'name': document.data().name,
							'content': document.data().nCompany,
							'id': document.id,
							'avatar': document.data().avatar,
							'denom': "entreprise"
						});
					})
				});

				this.$db.collection("companies").doc(this.company).collection("projects").onSnapshot((res) => {
					res.forEach((document) => {
						this.projects.push({
							'name': document.data().title,
							'id': document.id,
							'avatar': document.data().avatar
						});
					});
				});
			}
		},
		watch: {
			userInfo: {
				deep: true,
				handler() {
					this.company = this.userInfo.company;
				}
			},
			company: function() {
				this.init();
			},
			users: function() {
				this.people = this.users.concat(this.people);
			}
		},
		created() {
			this.companies = [];
			this.company = this.userInfo.company;

			this.$functions.queryDatabase(`users/${this.userInfo.id}/companies`).then((res) => {
				res.forEach((doc) => {
					this.$db.collection('companies').doc(doc.id).get().then(doc => {
						this.companies.push({
							...doc.data(),
							id: doc.id
						});

						this.companies.sort(function (a, b) {
							var textA = a.name.toUpperCase();
							var textB = b.name.toUpperCase();
							return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
						});
					});
				});
			});
		}
	}
</script>