import $db from "@/components/firebaseInit";
import firebase from "firebase/app";

const {
	Configuration,
	OpenAIApi
} = require("openai");

const configuration = new Configuration({
	apiKey: process.env.VUE_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);

const IACollection = "gNEqCLkYZvEakQHMmr7A";

/**
 * logCommand()
 * @param {Object} userObject 
 * @param {String} command 
 * @param {Number} tokens 
 */
function logCommand(userObject, command, tokens, response) {
	$db.collection('companies').doc(userObject.company).collection('ia').add({
		command: command,
		tokens: tokens,
		author: userObject.id,
		response: response,
		creationDate: new Date()
	});

	const increment = firebase.firestore.FieldValue.increment(tokens);

	$db.collection('companies').doc(userObject.company).collection('modules').doc(IACollection).update({
		tokens: increment
	});
}

function canRunIA(userObject, tokens) {
	return $db.collection('companies').doc(userObject.company).collection('modules').doc(IACollection).get().then((doc) => {
		if (!doc.data()) return false;
		if (doc.data().quota === "-1") return true;

		if (Number(doc.data().tokens + tokens) <= Number(doc.data().quota)) return true;
		else return false
	})
}

export let ai = {
	/**
	 * Ask OpenAI to write something and return the text and the tokens needed
	 * @param {Object} userObject 
	 * @param {String} query 
	 * @param {Number} length 
	 * @param {Number} n 
	 * @param {String} engine 
	 * @param {Number} best_of 
	 * @returns {Promise}
	 */
	async writeMeAThing(userObject, query, length = -1, echo = false, engine = 'text-davinci-001', best_of = 1) {
		if (length === -1) length = Math.abs(2048 - query.length);

		const AIprompt = query;
		let tokensUsed = AIprompt.split(/(?<=^(?:.{3})+)/).length;
		let final = false;

		let cria = canRunIA(userObject, tokensUsed);

		final = cria.then(async (value) => {
			if (!value) return false;

			const completion = await openai.createCompletion(engine, {
				prompt: AIprompt,
				temperature: 1,
				logprobs: 1,
				frequency_penalty: 2,
				presence_penalty: 2,
				echo: echo,

				max_tokens: length,
				best_of: best_of,
			});
			tokensUsed = completion.data.choices[0].logprobs.tokens.length;

			logCommand(userObject, AIprompt, tokensUsed, completion.data.choices[0].text);

			return completion;
		});

		return final;
	},

	/**
	 * Aks OpenAI to generate keywords from a text
	 * @param {Object} userObject 
	 * @param {String} prompt 
	 * @returns {Array<String>}
	 */
	generateKeywords(userObject, prompt) {
		const AIprompt = `Extrait les mot-clés de ce texte ${prompt}`;
		let tokensUsed = AIprompt.split(/(?<=^(?:.{3})+)/).length;
		let final = false;
		let keywords;

		let cria = canRunIA(userObject, tokensUsed);

		final = cria.then(async (value) => {
			if (!value) return false;

			const keywordsCompletion = await openai.createCompletion("text-davinci-001", {
				prompt: AIprompt,
				temperature: 0.3,
				max_tokens: 50,
				logprobs: 1,
				frequency_penalty: 2,
				presence_penalty: 2,
				echo: false
			});

			keywords = keywordsCompletion.data.choices[0].text.trim();
			tokensUsed = keywordsCompletion.data.choices[0].logprobs.tokens.length;

			keywords = keywords.toLowerCase();
			keywords = keywords.replace(/(-\s)|(,\s)|(\n-)/gm, "²");

			keywords = keywords.split("²");

			if (keywords[0] === "") keywords = keywords.slice(0, 1);

			logCommand(userObject, AIprompt, tokensUsed, keywordsCompletion.data.choices[0].text);

			return keywords;
		});

		return final;
	}
};
