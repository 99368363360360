import $db from "@/components/firebaseInit";
import $store from "@/store";

import { listUtils } from "@/components/toLoad/listUtils";

export let katana = {
	/**
	 * ? ROLES
	 */
	/**
	 * Change the position of the role of a user in a company
	 * @param {String} user 
	 * @param {String} company 
	 * @param {Number} role 
	 */
	statusRole(user, company, role) {
		$db.collection("companies").doc(company).collection("users").doc(user).set({
			modifyDate: new Date(),
			position: role
		}, {
			merge: true
		});

		$db.collection("users").doc(user).collection("companies").doc(company).set({
			modifyDate: new Date(),
			position: role
		}, {
			merge: true
		});
	},


	/**
	 * ? USERS
	 */
	/**
	 * Remove a user from a company
	 * @param {String} user 
	 * @param {String} company 
	 */
	removeUserFromCompany(user, company) {
		$db.collection("companies").doc(company).collection("users").doc(user).delete();

		$db.collection("users").doc(user).collection("companies").doc(company).delete();
	},
	/**
	 * Add a user from a company
	 * @param {String} user 
	 * @param {String} company 
	 */
	addUserToCompany(user, company, role=1) {
		$db.collection("companies").doc(company).collection("users").doc(user).set({
			addedDate: new Date(),
			position: role
		}, {
			merge: true
		});

		$db.collection("users").doc(user).collection("companies").doc(company).set({
			addedDate: new Date(),
			position: role
		}, {
			merge: true
		});
	},

	/**
	 * ? DATABASE
	 */
	/**
	 * Remove a document with all its sub-collections
	 * @param {FirebaseRef} res 
	 * @param {String} msg 
	 */
	async removeAllDataFromDocument(res, msg = "L'élément a été supprimé") {
		const path = res.path;
		const collSplit = path.split("/");
		const subCol = collSplit[collSplit.length - 2];

		try {
			if (listUtils.subcollections[subCol] && listUtils.subcollections[subCol].length > 0) await listUtils.subcollections[subCol].forEach((collection) => {
				$db.collection(`${path}/${collection}`).get().then((res) => {
					res.forEach((t) => {
						$db.doc(`${path}/${collection}/${t.id}`).delete();
					});
				});
			});

			$db.doc(path).delete();

			if (msg) $store.dispatch('setSnackbar', {
				text: msg,
				color: "success"
			});
		} catch (error) {
			$store.dispatch('setSnackbar', {
				text: error,
				color: "error"
			});
		}
	},

	async getUser(userID, privacy = true) {
		let d = await $db.collection("users").doc(userID).get();

		if (!d.exists) return {
			avatar: "",
			civility: "",
			firstName: "Inconnu",
			lastName: "",
			email: "",
			id: 0
		};

		if (privacy) return {
			avatar: d.data().avatar,
			civility: d.data().civility,
			firstName: d.data().firstName,
			lastName: d.data().lastName,
			email: d.data().email,
			company: d.data().company,
			id: d.id
		};
		else return {
			...d.data(),
			id: d.id
		};
	},

	/**
	 * ? MAILS
	 */
	/**
	 * Send a mail through Firebase sendMail ext
	 * @param {String} companyID 
	 * @param {String} userID 
	 * @param {String} subject 
	 * @param {String} content 
	 * @param {Object} recipients 
	 * @param {String} from 
	 * @returns {Promise}
	 */
	async sendMail(companyID, userID, subject, content, recipients = {}, from) {
		let data = {
			message: {
				'html': content,
				'subject': subject,
			},
			from: from,
			...recipients,
			katana: {
				company: companyID,
				sender: userID,
				recipients: recipients
			}
		};

		await $db.collection("companies").doc(companyID).get().then((doc) => {
			if (!from) data.from = `${doc.data().name} <${doc.data().email}>`;
		});

		const returnPromise = new Promise((resolve) => {
			$db.collection("mails").add(data).then((doc) => {
				resolve(doc);
			});
		});

		return returnPromise;
	},
	async getMails(id, direction="sender", needContext=false) {
		let data = [];

		let query = [`katana.${direction}`, "==", id];

		if (direction === "receiver") query = [`katana.recipients.to`, "array-contains", id];

		const taskPromise = new Promise((resolve) => {
			$db.collection("mails").where(query[0], query[1], query[2]).get().then((res) => {
				res.forEach(async (doc) => {
					let pushData = {
						...doc.data(),
						id: doc.id,
						creationDate: doc.data().delivery.startTime
					};

					if (needContext) {
						await $db.collection("users").doc(doc.data().katana.sender).get().then((user) => {
							pushData.author = user.data();
						});
					}

					switch (doc.data().delivery.state) {
						case "SUCCESS":
							pushData.status = 3;
							break;
						case "PENDING":
							pushData.status = 2;
							break;
						case "ERROR":
							pushData.status = 1;
							break;
						default:
							pushData.status = 0;
							break;
					}

					data.push(pushData);
				});

				resolve(data);
			});
		});

		return taskPromise;
	}
};
