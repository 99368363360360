<template>
	<v-footer class="pa-0" style="margin-top: 150px;">
		<v-sheet :height="tailleFooter"></v-sheet>
		<Transition transitionPosition="top" transitionRotate="0" />
		<v-col class="text-center" cols="12">
			<p class="my-0">{{ new Date().getFullYear() }} — <strong>InfographArt</strong></p>
			<p class="my-0">
				<v-icon class="mr-1" small>mdi-github</v-icon>v{{ version }}
				<span v-if="gitSha"> • <v-icon class=" mr-1" small>mdi-source-branch</v-icon>{{ gitSha }}</span>
			</p>
		</v-col>
		<v-sheet height="100px"></v-sheet>
	</v-footer>
</template>

<script>
	import packago from "../../package.json";
	import Transition from '@/components/graphics/transition';

	export default {
		name: 'bottom',
		components: {
			Transition,
		},
		data() {
			return {
				version: packago.version,
				tailleFooter: '200', // Nombre seul, interprété en pixels
			}
		},
		computed: {
			gitSha() {
				if(process.env.VUE_APP_GIT_SHA) return process.env.VUE_APP_GIT_SHA.slice(0, 7);
				else return false;
			}
		}
	}
</script>