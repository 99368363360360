import $db from "@/components/firebaseInit";
//import $firebase from "firebase/app";
import { listUtils } from "@/components/toLoad/listUtils";
import { models } from "@/components/toLoad/models";

import _moment from "moment";

export let functions = {
	/**
	 * Return the link with the good size of image input
	 * @param {String} path 
	 * @param {Number} size 
	 * @returns {String}
	 */
	getImgLink: function (path, size = 800) {
		/*const beginningPath = "https://firebasestorage.googleapis.com/v0/b/katana-main/o/";
		const endPath = /(\?alt=[0-9a-z]+)(&token=[0-9a-z-]+)/gmi;
		const storagePath = path
			.replace(beginningPath, "")
			.replace(endPath, "")
			.replace(/%2F/gm, '/');

		function getDl(ref) {
			return ref.getMetadata().catch(() => {
				return;
			});
		}
		
		const storageRef = await $firebase.app().storage("gs://katana-main").ref(storagePath + "_800x800");
		const t = getDl(storageRef);

		await t.finally((value) => {
			if (value) resizedImage = JSON.parse(value.customMetadata.resizedImage);
		});

		console.log(resizedImage)

		const storageRef = $firebase.app().storage("gs://katana-main").ref(storagePath + "_800x800");

		console.log(storageRef)*/

		let resizedImage = true;

		if (!path) return '';

		if (resizedImage) {
			return path.replace("?alt", `_${size}x${size}?alt`);
		}
		else {
			return path;
		}
	},
	/**
	 * Returns a string from a provided date and format
	 * @param {Date} date 
	 * @param {String} format 
	 * @returns {String}
	 */
	getDateFormat(date, format = "DD/MM/YYYY à HH:mm") {
		if (date.toDate) return _moment(date.toDate()).format(format)
		else _moment(date).format(format)
	},
	/**
	 * Returns if a user can access a module
	 * @param {String} user 
	 * @param {String} module 
	 * @returns {Boolean}
	 */
	hasModule: async function (user, module) {
		var result = await $db.collection("companies").doc(user.company).collection("modules").doc(module).get().then(doc => {
			if (doc.data()) return true;
			else return false;
		});

		return result;
	},
	/**
	 * Open a new tab with the provided link
	 * @param {String} link 
	 * @param {String} param 
	 */
	goTo: function (link, param = "_blank") {
		window.open(link, param);
	},

	/**
	 * Returns the position of a item with a specific attribute in an array
	 * @param {Array} array 
	 * @param {String} attr 
	 * @param {String} value 
	 * @returns {Number}
	 */
	findWithAttr: function (array, attr, value) {
		for(var i = 0; i < array.length; i += 1) {
			if(array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	},

	/**
	 * Gets the next index of a provided array
	 * @param {Array} array 
	 * @param {Number} n 
	 * @returns {Number}
	 */
	getNextIndex: function(array, n) {
		var numbers = [];

		if (array.length > 1) {
			array.forEach((item) => {
				numbers.push(Number(item[n]));
			});

			if (!Math.max(...numbers)) {
				return 1;
			}

			return Math.max(...numbers) + 1;
		} else {
			return 1;
		}
	},

	/**
	 * Returns a hexadecimal from a provided string
	 * @param {String} str 
	 * @returns {String<Hexa>}
	 */
	convertToHex: function(str) {
		var result = '';
		if (!str) return;
		for (var i = 0; i < 3; i++) {
			result += str.charCodeAt(i).toString(16);
		}

		return result;
	},

	/**
	 * Gets a number within 0 and provided size
	 * @param {Number} size 
	 * @returns {Number}
	 */
	randomNumbers: function (size) {
		var result = '';
		for (var i = 0; i < size; i++) {
			result += String(Math.floor(Math.random() * 10));
		}

		return result;
	},

	/**
	 * queryDatabase() 
	 * @param {String} docRef 
	 * @param {Array} whereClauses 
	 * @param {Array} orderBy 
	 * @param {Number} limit 
	 * @returns {Promise}
	 */
	async queryDatabase(docRef, whereClauses = [], orderBy = [null, "asc"], limit = -1) {
		let ref = $db;
		const path = docRef.split("/");

		path.forEach((stone, index) => {
			if (stone.length == 0) return;

			if (index == 0 || index % 2 == 0) ref = ref.collection(stone);
			else ref = ref.doc(stone);
		});

		whereClauses.forEach((clause) => {
			ref = ref.where(...clause);
		})

		if (orderBy[0]) ref = ref.orderBy(orderBy[0], orderBy[1]);

		if (limit > -1) ref = ref.limit(limit);

		let queryPromise = ref.get().then((doc) => {
			if (doc.exists || doc.size) {
				if (doc.exists) return [doc];

				return doc;
			} else {
				return false;
			}
		}).catch(error => {
			return error;
		});

		return queryPromise;
	},

	/**
	 * Returns a tasks list with the parameters provided
	 * @param {String} company 
	 * @param {FirebaseRef} ref 
	 * @param {Array<Number>} status 
	 * @param {Number} limit 
	 * @returns {Promise}
	 */
	async getTasks(company, ref, status = [1, 2], limit = 10) {
		function pushToCustomer(r, tasks, document, category, addedData = null, whatData = null) {
			var returnData = {
				...document.data(),
				category: category.label,
				id: document.id
			};

			if (addedData) returnData[whatData] = addedData;

			if (r == -1) tasks.push(returnData);

			return tasks;
		}

		function findWithAttr(array, attr, value) {
			for (var i = 0; i < array.length; i += 1) {
				if (array[i][attr] === value) {
					return i;
				}
			}
			return -1;
		}

		let tempTasks = [];
		let newRef;

		if (limit == -1) newRef = ref;
		else newRef = ref.limit(limit);

		const taskPromise = new Promise((resolve) => {
			newRef.where("status", "in", status).orderBy("dueDate", "asc").get().then((res) => {
				tempTasks = []

				res.forEach(async (document) => {
					var data = document.data();

					if (document.ref.path && !document.ref.path.includes(company)) return;

					var r = findWithAttr(listUtils.itemCategory, "label", data.category);

					if (data.participants && data.participants.length) {
						var participantsData = [];

						data.participants.forEach((item) => {

							$db.collection("companies").doc(company).collection("customers").doc(item).get().then((doc) => {
								if (doc.data()) {
									participantsData.push({
										...models.customer,
										...doc.data(),
										id: doc.id
									});
								}
								else {
									$db.collection("companies").doc(company).collection("companies").doc(item).get().then((doc) => {
										if (doc.data()) {
											participantsData.push({
												...models.company,
												...doc.data(),
												id: doc.id
											});
										}
										else {
											$db.collection("users").doc(item).get().then((doc) => {
												if (doc.data()) {
													participantsData.push({
														...models.user,
														...doc.data(),
														id: doc.id,
														report: false
													});
												}
											});
										}
									});
								}
							});

							tempTasks = pushToCustomer(
								findWithAttr(
									tempTasks,
									"id",
									document.id
								),
								tempTasks,
								document,
								listUtils.itemCategory[r],
								participantsData,
								"participantsData"
							);

						})
					} else {
						tempTasks = pushToCustomer(
							findWithAttr(
								tempTasks,
								"id",
								document.id
							),
							tempTasks,
							document,
							listUtils.itemCategory[r]
						);
					}

					if (document.data().project) {
						$db.collection("companies").doc(company).collection("projects").doc(document.data().project.id ? document.data().project.id : document.data().project).get().then((doc) => {
							if (doc.data()) {
								var index = findWithAttr(
									tempTasks,
									"id",
									document.id
								);

								tempTasks[index].projectData = {
									...doc.data(),
									id: doc.id
								};
							}
						});
					}
				});
				
				resolve(tempTasks);
			});
		});

		return taskPromise;
	}
};
