import Vue from "vue";
import Vuex from "vuex";

import firebase from "firebase/app";
require('firebase/auth');
import db from "../components/firebaseInit";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		snackbar: {}
	},
	mutations: {
		SET_SNACKBAR(state, snackbar) {
			state.snackbar = snackbar;
		},
		MODULE(state, data) {
			state.module = data;
		}
	},
	actions: {
		setSnackbar({
			commit
		}, snackbar) {
			snackbar.showing = true;

			snackbar.color = snackbar.color || 'success';
			
			snackbar.timeout = snackbar.timeout || 10000;

			commit('SET_SNACKBAR', snackbar);
		},
		async hasAccess({ commit }, data) {
			commit("MODULE", data);

			const userDoc = await db.collection("users").doc(firebase.auth().currentUser.uid).get();
			const doc = await db.collection("companies").doc(userDoc.data().company).collection("modules").doc(data.module).get();
			if (doc.data())
				return true;
			else
				return false;
		}
	},
	modules: {}
});