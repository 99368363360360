export let listUtils = {
	bankCountries: [
		"FR",
		"BE",
		"ES"
	],

	civility: [
		"Monsieur",
		"Madame",
		"Autre"
	],
	shortCivility: [
		"Mr",
		"Mme"
	],
	
	elementStatus: [
		"Non Pris en Charge",
		"En Attente",
		"En Cours",
		"Complet",
	],

	mailStatus: [
		"Non pris en charge",
		"Non envoyé",
		"Envoyé",
		"Reçu"
	],

	entityStatus: [
		{
			label: "Non pris en charge",
			color: "grey"
		},
		{
			label: "En attente",
			color: "#f83e70"
		},
		{
			label: "En cours",
			color: "#ffaa2c"
		},
		{
			label: "Complet",
			color: "#3cd1c2"
		}
	],

	funnelEntityStatus: [
		{
			label: "Prospet",
			color: "blue"
		},
		{
			label: "Contact",
			color: "blue darken-3"
		},
		{
			label: "Lead",
			color: "purple"
		},
		{
			label: "Finaliste",
			color: "orange lighten-1"
		},
		{
			label: "Client",
			color: "green"
		}
	],

	quoteStatus: [
		"Brouillon",
		"Attente Client",
		"Accepté",
		"Refusé"
	],

	productStatus: [
		"Hors stock",
		"En stock",
		"Précommande",
		"Réapprovisionnement"
	],

	projectCategory: [
		'Web',
		'Print',
		'3D'
	],
	
	itemCategory: [
		{
			label: "Transport",
			color: "grey"
		},
		{
			label: "Livraison",
			color: "yellow darken-1"
		},
		{
			label: "Logistique",
			color: "green darken-1"
		},
		{
			label: "Développement",
			color: "info"
		},
		{
			label: "Contact Client",
			color: "orange lighten-1"
		},
		{
			label: "Organisation Interne",
			color: "primary"
		},
		{
			label: "Communication",
			color: "blue darken-2"
		}
	],
	
	priority: [
		"5 - Minimale",
		"4 - Basse",
		"3 - Normale",
		"2 - Haute",
		"1 - Maximale",
	],

	rankInCompany: [
		"Inconnu",
		"Propriétaire",
		"Superviseur",
		"Membre",
		"Client"
	],

	subcollections: {
		'companies': [
			'blogs',
			'companies',
			'customers',
			'modules',
			'products',
			'projects',
			'quotes',
			'tasks',
			'teams'
		],
		'customers': [
			'remarks'
		],
		'modules': [],
		'products': [],
		'ranks': [],
		'users': [
			'qrcodes',
			'ranks'
		],
		'projects': [
			'integrations'
		],
		'blogs': [
			'articles',
			'integrations'
		],
		'quotes': [
			'products'
		],
		'teams': [
			'members'
		]
	},

	iconActions: {
		// EMAIL
		'sendEmail': ['email-send'],
		'receiveEmail': ['email-received'],
		'errorEmail': ['email-alert', 'error'],

		// CALLTOACTION
		'contactCTA': ['bullseye-arrow'],

		// QUOTES
		'quoteAccepted': ['clipboard-check'],
		'quoteRefused': ['clipboard-remove'],

		// INVOICE
		'invoiceAccepted': ['cash-check'],
		'invoicePaid': ['cash-fast'],
		'invoiceRefused': ['cash-remove'],
	}
};