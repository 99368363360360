export let cssGlobal = {
	getStatus: function (param, type = "default") {
		if (type == "product") {		
			if (param === "Hors stock") return "#f83e70";
			else if (param === "En stock") return "#3cd1c2";
			else if (param === "Précommande") return "#3cd1c2";
			else if (param === "Réapprovisionnement") return "#ffaa2c";
		}
		else if (type == "quote") {
			if (param === "Brouillon") return "#bdc3c7";
			else if (param === "Attente Client") return "#ffaa2c";
			else if (param === "Accepté") return "#3cd1c2";
			else if (param === "Refusé") return "#f83e70";
		}
		else if (type == "mail") {
			if (param == "Non pris en charge") return "#3cd1c2";
			if (param == "Non envoyé") return "#f83e70";
			if (param == "Envoyé") return "#ffaa2c";
			if (param == "Reçu") return "#3cd1c2";
		}
		else {
			if (param === "overdue") return "#f83e70";
			else if (param === "Non Livré") return "#f83e70";
			else if (param === "Non Pris en Charge") return "#f83e70";
			else if (param === "Impayé") return "#f83e70";
			else if (param === "En Attente") return "#f83e70";
			else if (param === "ongoing") return "#ffaa2c";
			else if (param === "En Cours") return "#ffaa2c";
			else if (param === "Complet") return "#3cd1c2";
			else if (param === "Terminé") return "#3cd1c2";
			else if (param === "Satisfait") return "#3cd1c2";

			else return "#3cd1c2";
		} 
	},
	getBool: function (param, color) {
		if (param) {
			if (color) {
				return "#3cd1c2";
			}
			else {
				return "Oui";
			}
		}
		else {
			if (color) {
				return "#f83e70";
			}
			else {
				return "Non";
			}
		}
	}
};