import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

import VueHead from "vue-head";
import Vuex from "vuex";
import store from "./store";

import axios from "axios";

import firebase from "firebase/app";
import db from "./components/firebaseInit";
import "firebase/auth";
import "firebase/performance";

import 'web-vitals';

firebase.performance();
Vue.prototype.$db = db;
Vue.prototype.$firebase = firebase;

import { appConfig } from "./components/toLoad/appConfig";

import { cssGlobal } from "./components/toLoad/cssGlobal";
import { listUtils } from "./components/toLoad/listUtils";
import { fieldsRules } from "./components/toLoad/fieldsRules";
import { models } from "./components/toLoad/models";
import { functions } from "./components/toLoad/functions";
import { tableHeaders } from "./components/toLoad/tableHeaders";
import { ai } from "./components/toLoad/ai";
import { katana } from "./components/toLoad/katana";

import './registerServiceWorker';

import moment_ from "moment";
Vue.prototype.$moment = moment_;

Vue.use(require("vue-moment"));

Vue.use(Vuex);
Vue.use(VueHead, {
	separator: "—",
	complement: "Katana"
});

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

Vue.prototype.$appConfig = appConfig;

Vue.prototype.$cssGlobal = cssGlobal;
Vue.prototype.$listUtils = listUtils;
Vue.prototype.$fieldsRules = fieldsRules;
Vue.prototype.$models = models;
Vue.prototype.$functions = functions;
Vue.prototype.$tableHeaders = tableHeaders;
Vue.prototype.$ai = ai;
Vue.prototype.$katana = katana;

Vue.prototype.$performance = true;

firebase.auth().onAuthStateChanged(() => {
	new Vue({
		router,
		vuetify,
		store,
		render: h => h(App)
	}).$mount("#app");
});
