export let tableHeaders = {
	customers: [
		{
			text: "",
			value: "status",
			width: 0,
			align: "start",
		},
		{
			text: "",
			sortable: false,
			value: "avatar",
			width: 0,
			align: "start",
		},
		{
			text: "n°",
			value: "nClient",
			width: 65,
			align: "start",
		},
		{
			text: "Nom",
			value: "lastName",
			width: 75,
			align: "start",
		},
		{
			text: "Prénom",
			value: "firstName",
			align: "start",
		},
		{
			text: "",
			sortable: false,
			value: "manage",
			width: 140,
		},
	],

	companies: [{
		text: "",
		value: "status",
		width: 0,
		align: "start",
		},
		{
			text: "",
			sortable: false,
			value: "avatar",
			width: 0,
			align: "start",
		},
		{
			text: 'n°',
			value: 'nCompany',
			width: 65,
			align: "start",
		},
		{
			text: 'Entreprise',
			value: 'name',
		},
		{
			text: "",
			sortable: false,
			value: "manage",
			width: 140,
		},
	],

	products: [{
		text: "",
		value: "status",
		width: 10,
		align: "start",
		},
		{
			text: "",
			value: "pictures",
			sortable: false,
			width: 50,
		},

		{
			text: "Référence",
			value: "reference",
			width: 100,
		},
		{
			text: "Prix",
			value: "value",
			width: 100,
		},
		{
			text: "Nom",
			value: "name",

		},
		{
			align: "end",
			text: "",
			value: "manage",
			width: 140,
			sortable: false
		},
	],

	projects: [{
			text: "",
			value: "status",
			width: 10,
			align: "start",
		},
		{
			sortable: false,
			value: 'avatar',
			width: 50,
		},
		{
			text: 'Type',
			value: 'type',
			width: 100,
		},
		{
			text: 'Valeur',
			value: 'value',
			width: 100,
		},
		{
			text: 'Date de rendu',
			value: 'due',
			width: 100,
		},

		{
			text: 'Client',
			value: 'customers'
		},

		{
			text: 'Intitulé',
			sortable: false,
			value: 'title',
		},
		{
			text: "",
			sortable: false,
			value: "manage",
			width: 140,
		},
	],

	quotes: [
		{
			text: "",
			value: "status",
			width: 10,
			align: "start",
		},{
			align: "start",
			sortable: false,
		},
		{
			text: "Numéro devis",
			value: "nQuote",
		},
		{
			text: "Reférence",
			value: "reference",
		},
		{
			text: "Client",
			value: "customer",
		},
		{
			text: "Prix",
			value: "value",
		},

		{
			text: "",
			value: "manage",
		},
	],

	quoteElements: [{
			text: 'Quantité',
			align: 'center',
			sortable: false,
			width: 100,
			value: 'quantity',
		},
		{
			text: 'Aperçu',
			align: 'center',
			sortable: false,
			value: 'pictures',
		},
		{
			text: 'Produit',
			align: 'start',
			value: 'product'
		},
		{
			text: 'Prix unité',
			align: 'center',
			width: 200,
			value: 'value'
		},
		{
			text: 'Total',
			align: 'center',
			width: 200,
			value: 'total',
		}
	],

	tasks: [
		{
			text: "Status",
			value: "status",
			width: 90,
			align: "start",
		},{
			text: "Catégorie",
			value: "category",
			width: 10,
		},
		{
			text: "Tâche",
			value: "task",
			groupable: false,
			align: 'left',
			width: 300,
		},
		{
			text: "Priorité",
			value: "priority",
			width: 150,
		},
		{
			text: "Deadline",
			value: "dueDate",
			groupable: false,
			width: 100,
		},
		{
			text: "Particip.",
			value: "participants",
			groupable: false,
			width: 150,
		},
		{
			text: "Projet",
			value: "project",
			groupable: false,
			width: 100,
		},
		{
			text: "",
			value: "manage",
			groupable: false,
			align: 'right',
			width: 200,
		},
	],

	remarks: [{
			text: "Date",
			value: 'creationDate',
			width: 170,
		},
		{
			text: 'Auteur',
			value: 'author',
			width: 170,
		},
		{
			text: 'Remarque',
			value: 'content'
		},
	],

	blogs: [{
			text: "Nom",
			value: 'name'
		},
		{
			text: 'Date de création',
			value: 'creationDate'
		},
		{
			text: 'Actions',
			align: 'center',
			value: 'actions',
			sortable: false,
		}
	],

	ia: [{
			text: "Commande",
			value: 'command'
		},
		{
			text: "Auteur",
			value: 'author'
		},
		{
			text: "Tokens",
			value: 'tokens'
		},
		{
			text: 'Date de création',
			value: 'creationDate'
		}
	],

	mails: [
		{
			text: "Statut",
			value: 'status'
		},
		{
			text: "Auteur",
			value: 'author'
		},
		{
			text: "Sujet",
			value: 'message.subject'
		},
		{
			text: 'Date d\'envoi',
			value: 'creationDate'
		}
	],
};