<template>
	<v-img :src="$appConfig.appTransitionOpen" class="pa-0 my-0 mx-auto"
		:style="'position: absolute; ' + `${transitionPosition}` + ': -1px; width: 100vw; transform: rotate(' + `${transitionRotate}` + 'deg);'" :class="{'inverted': !$vuetify.theme.dark == 0}"
		>
	</v-img>
</template>

<style>
	.inverted {
		filter: invert(98%) sepia(6%) saturate(421%) hue-rotate(314deg) brightness(91%) contrast(89%);
	}
</style>

<script>
	export default {
		props: {
			'id': {
				type: String
			},
			transitionRotate: String,
			transitionPosition: String,
		},
		components: {

		},
		data() {
			return {};
		},

		created() {},
	}
</script>