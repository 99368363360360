<template>
	<nav>

		<!-- Navigation Top -->
		<v-app-bar flat style="z-index: 2;">

			<v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer" app v-if="loggedIn"></v-app-bar-nav-icon>

			<v-toolbar-title class="text-uppercase grey--text pl-4" v-if="$vuetify.breakpoint.mdAndUp">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn outlined hover plain tile v-bind="attrs" v-on="on" elevation="0" text to="/"
							class="ml-0">
							<span class="font-weight-regular">Katana</span>
							<span class="ml-1 font-weight-light" v-if="userData.company"> -
								{{ compData.name }}</span>
							<span class="ml-1 font-weight-light" v-else> - Invité</span>
						</v-btn>
					</template>

					<span>
						<v-icon left small color="white">mdi-view-dashboard</v-icon>Retour au Dashboard
					</span>
				</v-tooltip>

			</v-toolbar-title>


			<v-spacer></v-spacer>






			<v-dialog v-model="dialogs.todo" transition="dialog-top-transition"
				v-if="loggedIn && $route.name && !$route.name.toLowerCase().includes('task')" max-width="1280">
				<template v-slot:activator="{ on, attrs }">

					<v-btn outlined text small v-bind="attrs" v-on="on" class="float-right text-truncate">
						+ Tâche
					</v-btn>

				</template>

				<TaskAddDialog v-if="userData.company" v-model="dialogs.todo" :userInfo="userData"></TaskAddDialog>
			</v-dialog>

			<!-- <PerformanceModeSwitch /> -->
			<DarkModeSwitch />

			<v-list-item-avatar @click="drawer2 = !drawer2" v-if="loggedIn" app>
				<img v-if="loggedIn && userData.avatar" :src="$functions.getImgLink(userData.avatar, 50)">
				<img v-else src="https://via.placeholder.com/128">
			</v-list-item-avatar>

		</v-app-bar>

		<!-- Navigation Droite -->
		<v-navigation-drawer v-model="drawer2" v-if="loggedIn" color="primary" right temporary absolute dark>
			<v-list class="py-0">
				<v-list-item two-line :class="false && 'px-0'" v-if="loggedIn">
					<v-list-item-avatar>
						<img v-if="userData.avatar" :src="$functions.getImgLink(userData.avatar, 50)">
						<img v-else src="https://via.placeholder.com/128">
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>{{ userData.firstName }} {{ userData.lastName }}</v-list-item-title>
						<v-list-item-subtitle v-if="userData.company">{{ compData.name }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-divider></v-divider>

				<v-list-item v-for="item in items" :key="item.title" link router :to="item.route">

					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>

				</v-list-item>

				<div v-if="loggedIn">

					<v-divider></v-divider>

					<v-list-item v-for="item in companies" :key="item.title" @click="changeCompany(item.id, item.name)">

						<v-list-item-content>
							<v-list-item-title>{{ item.name }}</v-list-item-title>
						</v-list-item-content>

					</v-list-item>

					<v-divider></v-divider>

					<v-list-item link router:to="/access" @click="signOut">
						<v-list-item-icon>
							<v-icon>mdi-logout</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>Déconnexion</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</div>
			</v-list>
		</v-navigation-drawer>


		<!-- Navigation Gauche -->


		<v-navigation-drawer v-if="loggedIn" :expand-on-hover="$vuetify.breakpoint.mdAndUp" :permanent="$vuetify.breakpoint.mdAndUp" v-model="drawer" app collapse
			:style="$vuetify.theme.dark ? 'background-color: rgba(256,256,256,0.1); backdrop-filter: blur(20px);' : 'background-color: rgba(256,256,256,0.6); backdrop-filter: blur(20px);'">
			<v-list>
				<v-list-item v-for="link in links" :key='link.id' router :to="link.route">
					<v-list-item-icon>
						<v-icon>{{ link.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ link.text }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>

			<v-list v-show="adminAccess">
				<v-list-item v-for="link in adminLinks" :key='link.text' router :to="link.route">
					<v-list-item-icon>
						<v-icon>{{ link.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ link.text }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

		</v-navigation-drawer>

		<!-- Overlay -->
		<v-sheet v-if="drawer2" height="100%" width="100%" color="transparent"
			style="position: absolute; top: 0; left: 0; z-index: 6; backdrop-filter: blur(4px);"></v-sheet>


	</nav>
</template>



<script>
	import DarkModeSwitch from "@/components/modules/@widgets/DarkModeSwitch";
	// import PerformanceModeSwitch from "@/components/modules/@widgets/PerformanceModeSwitch";
	import TaskAddDialog from '@/components/modules/@tasks/TaskAddDialog';

	export default {
		props: {
			'id': {
				type: String
			}
		},
		components: {
			DarkModeSwitch,
			// PerformanceModeSwitch,
			TaskAddDialog,
		},
		data() {
			var itemsList = this.$firebase.auth().currentUser ? [{
				title: 'Profil',
				icon: 'mdi-account',
				route: '/profile'
			}, {
				title: 'Mon Entreprise',
				icon: 'mdi-office-building-outline',
				route: '/my-company'
			}] : [{
				title: 'Se connecter',
				icon: 'mdi-login',
				route: '/access'
			}];


			return {
				dialogs: {
					todo: false,
					genQR: false
				},

				drawer: false,
				drawer2: false,

				darkMode: this.$vuetify.theme.dark,

				links: [],
				adminLinks: [{
					icon: 'mdi-account-cowboy-hat',
					text: 'Administration',
					route: '/administration'
				}, {
					icon: 'mdi-plus',
					text: 'Création',
					route: '/administration/creation'
				}, ],

				items: itemsList,
				companies: [],

				user: this.$firebase.auth().currentUser,

				adminAccess: false,

				userData: this.$models.user,
				compData: this.$models.company
			}
		},
		created() {
			this.loggedIn = this.$firebase.auth().currentUser ? true : false;

			this.changeDarkTheme();

			if (this.loggedIn) {
				this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
					if (!this.$route.meta.safePlace) {
						if(this.userData.company && this.userData.company != doc.data().company) {
							this.$router.replace({
								path: "/"
							});
						}
					}

					this.userData = {
						...doc.data(),
						id: doc.id
					};

					// Vérification admin access
					this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).collection("ranks").doc("BzAcMoJjpbCaNFyQSJ3N").onSnapshot(doc => {
						this.adminAccess = doc.data() ? true : false;
					});

					// Récupération infos entreprise
					this.$db.collection("companies").doc(doc.data().company).onSnapshot(async (doc) => {

						await this.resetNav();

						this.compData = {
							...doc.data(),
							id: doc.id
						};

						// Construction de la navigation avec les liens dont a accès l'entreprise
						this.controlNavLinks("8JJgD8UzYi1c6H3tukKF", {
							icon: 'mdi-folder-account',
							text: 'Clients',
							route: '/customers'
						});

						this.controlNavLinks("MuIS4a0uWMkvYxL2o9v1", {
							icon: 'mdi-domain',
							text: 'Entreprises',
							route: '/companies'
						});

						this.controlNavLinks("GkQYUDfVSOv91r2CS9KN", {
							icon: 'mdi-email-multiple',
							text: 'Messagerie',
							route: '/messaging'
						});

						/*this.controlNavLinks("JAms0PMwguesdZvvXvIL", {
							icon: 'mdi-cash-multiple',
							text: 'Factures',
							route: '/invoices'
						});*/

						this.controlNavLinks("YzeCWO5vQIgYKrdZCuWz", {
							icon: 'mdi-clipboard-multiple-outline',
							text: 'Devis',
							route: '/quotes'
						});

						this.controlNavLinks("Dk1wdkEvdgyIPmJAg1uv", {
							icon: 'mdi-folder',
							text: 'Projets',
							route: '/projects'
						});

						this.controlNavLinks("dBNa5WjEnL1tjUJBDVHd", {
							icon: 'mdi-cube',
							text: 'Produits',
							route: '/products'
						});

						this.controlNavLinks("zS95PXCJ8QRCXsNgRMSX", {
							icon: 'mdi-newspaper',
							text: 'Blogs',
							route: '/blogs'
						});

						this.controlNavLinks("GPvYlONpjCDqzW6sckkw", {
							icon: 'mdi-account-multiple',
							text: 'Équipes',
							route: '/team'
						});

						this.controlNavLinks("iqgmYXQqUR2Gxk8DfsqD", {
							icon: 'mdi-wrench',
							text: 'Outils',
							route: '/tools'
						});

						this.controlNavLinks("dbTaPi7CCfFrmJfXu8NP", {
							icon: 'mdi-account-multiple',
							text: 'Vrchitect',
							route: '/vrchitect'
						});
					});

					// Récupération de toutes les entreprises de l'utilisateur
					this.companies = [];

					this.$functions.queryDatabase(`users/${this.userData.id}/companies`).then((res) => {
						res.forEach((doc) => {
							this.$db.collection('companies').doc(doc.id).get().then(doc => {
								this.companies.push({
									...doc.data(),
									id: doc.id
								});

								this.companies.sort(function (a, b) {
									var textA = a.name.toUpperCase();
									var textB = b.name.toUpperCase();
									return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
								});
							});
						})
					});
				});
			}
		},
		methods: {
			changeDarkTheme() {
				const favicon = document.getElementById("favicon");

				this.darkMode = this.$vuetify.theme.dark;

				if (this.darkMode) favicon.href = "https://cdn.infographart.com/logos/KATANA/favicon-white.ico";
				else favicon.href = "https://cdn.infographart.com/logos/KATANA/favicon.ico";
			},
			resetNav() {
				this.links = [{
					icon: 'mdi-view-dashboard',
					text: 'Tableau de bord',
					route: '/',
					id: 0
				}];
			},
			controlNavLinks(module, linkToAdd) {
				this.$db.collection("companies").doc(this.compData.id).collection("modules").doc(module).get().then(
					doc => {
						var index = this.links.map(function (e) {
							return e.id;
						}).indexOf(module);

						if (doc.data() && index == -1) {
							this.links.push({
								...linkToAdd,
								id: module
							});
						}
					});
			},
			async changeCompany(compID, compName) {
				if (!this.$route.meta.safePlace) await this.$router.replace({
					path: "/"
				});

				this.$db.collection('users').doc(this.$firebase.auth().currentUser.uid).set({
					'company': compID,
				}, {
					merge: true
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `Votre entreprise active est désormais ${compName}.`,
						color: 'info'
					});
				}).catch((err) => {
					this.$store.dispatch('setSnackbar', {
						text: err,
						color: "error"
					});
				});
			},
			async signOut() {
				try {
					await this.$firebase.auth().signOut();
					this.$router.replace({
						path: "Access"
					});
				} catch (err) {
					console.log(err);
				}
			}
		}
	}

</script>
