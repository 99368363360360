<template>
	<v-app>

		<Navigation />

		<v-snackbar v-model="snackbar.showing" :centered="$vuetify.breakpoint.xsOnly"
			:right="$vuetify.breakpoint.smAndUp" :timeout="snackbar.timeout" :color="snackbar.color" height="1.5em"
			outlined text top class="mt-16">
			<span>{{ snackbar.text }}</span>

			<template v-slot:action="{ attrs }">
				<v-btn icon class="float-right text--lighten-1" v-bind="attrs" x-small :color="snackbar.color">
					<v-icon @click="snackbar.showing = false" small>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

		<v-main>
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</v-main>

		<Footer />

	</v-app>
</template>

<style>
	::-webkit-scrollbar {
		width: 4px;
		height: 0;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: all .15s ease;
	}

</style>

<style>
	@import "./sass/performance.css";
	@import "./sass/quality.css";
	@import "./sass/framework.css";
	@import "./sass/variables.css";
	@import "./sass/animations.css";
	@import "./sass/style.css";
</style>

<script>
	import jsonPackage from "@/../package.json";
	import Navigation from './components/Navigation';
	import Footer from './components/Footer';

	import {
		mapState
	} from 'vuex';

	export default {
		name: 'App',

		components: {
			Navigation,
			Footer,
		},

		data() {
			return {
			}
		},
		computed: {
			...mapState(['snackbar'])
		},
		watch: {
			$route: function () {
				this.checkVersion();
			}
		},
		methods: {
			checkVersion() {
				if (localStorage.getItem('katana:appVer') != jsonPackage.version) {
					localStorage.setItem('katana:lastCheck', JSON.stringify({
						timestamp: new Date(),
						verFrom: localStorage.getItem('katana:appVer')
					}));
					window.location.reload();
				}

				localStorage.setItem('katana:appVer', jsonPackage.version);
			}
		},
		created() {
			this.checkVersion();
		}
	};

</script>
